import { ReactElement } from "react";
import { ContextCollection } from "../../contexts/DependencyContext";
import Text from "../../components/Widgets/Post/Text";
import HighlightedText from "../../components/Widgets/Post/HighlightedText";
import ImageGallery from "../../components/Widgets/ImageGallery";
import NoWidget from "../../components/Widgets/NoWidget";
import { IGenericWidget } from "./IGenericWidget";

export type WidgetProps = IGenericWidget & GatsbyTypes.strapi_PostsContentDynamicZone;

export default class PostWidgetService {
    public getType(widgetProps: WidgetProps): string {
        return (widgetProps.__typename as string)?.replace("strapi_Component", "")?.toLowerCase();
    }

    public getKey(widgetProps: WidgetProps): string {
        return `${this.getType(widgetProps)}-${widgetProps.id}`;
    }

    public getWidget(widgetProps: WidgetProps): ReactElement {
        const type = this.getType(widgetProps);

        switch (type) {
            case "posttext":
                return <Text widgetProps={widgetProps as GatsbyTypes.strapi_ComponentPostText} />;

            case "posthighlightedtext":
                return <HighlightedText widgetProps={widgetProps as GatsbyTypes.strapi_ComponentPostHighlightedText} />;

            case "postmediacontent":
                return <ImageGallery allImages={(widgetProps as GatsbyTypes.strapi_ComponentPostMediaContent).media as GatsbyTypes.strapi_UploadFile[]} />;

            default:
                return <NoWidget {...widgetProps} />;
        }
    }
}

ContextCollection.registerTransient(PostWidgetService);
