import { RefObject, useRef, useEffect, useState } from "react";
import clamp from "../shared/Clamp";

interface ISnapControlResult<TElement extends HTMLElement> {
    galleryRef: RefObject<TElement>;
    progress: number;
    previousItem: () => void;
    nextItem: () => void;
}

export default function useSnapScroll<TElement extends HTMLElement>(): ISnapControlResult<TElement> {
    const [progress, setProgress] = useState(0);
    const galleryRef = useRef<TElement>(null);

    function getScrollValues(items: TElement): { gap: number; itemWidth: number; maxScroll: number } {
        // calculates the gap between elements if any.
        const firstElement = items.firstChild as HTMLElement;
        const secondElement = items.childNodes.length > 1 ? (items.childNodes[1] as HTMLElement) : undefined;
        const gap = firstElement && secondElement ? secondElement.offsetLeft - firstElement.offsetLeft - firstElement.clientWidth : 0;

        // calculates how much do we need to scroll.
        const itemWidth = firstElement?.clientWidth ?? 0;
        const maxScroll = items.scrollWidth - items.clientWidth;

        return { gap, itemWidth, maxScroll };
    }

    useEffect(() => {
        setTimeout(() => {
            if (!galleryRef.current) return;
            const { maxScroll } = getScrollValues(galleryRef.current);
            setProgress(maxScroll > 0 ? 0 : -1);
        }, 500);
    }, []);

    function navigate(direction: 1 | -1): void {
        if (!galleryRef.current) return;

        const items = galleryRef.current;
        const { gap, itemWidth, maxScroll } = getScrollValues(items);
        const increment = (itemWidth + gap) * direction;
        const scrollOffset = clamp(items.scrollLeft + increment, 0, maxScroll);

        // calculates the scroll progress.
        const newProgress = clamp(scrollOffset / maxScroll, 0, 1);

        items.style.setProperty("--scroll-progress", newProgress.toString());
        items.scrollTo({ left: scrollOffset, behavior: "smooth" });

        setProgress(newProgress);
    }

    function previousItem(): void {
        navigate(-1);
    }

    function nextItem(): void {
        navigate(1);
    }

    return { galleryRef, progress, previousItem, nextItem };
}
