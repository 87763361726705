import { ContextCollection } from "../contexts/DependencyContext";
import { TelemetryService } from "../services/telemetry/TelemetryService";
import { navigate } from "gatsby";

export class NavigationService {
    constructor(private telemetryService: TelemetryService) {}

    async navigateAsync(url: string): Promise<void> {
        this.telemetryService.navigate(url);
        await navigate(url);
    }
}

ContextCollection.registerSingleton(NavigationService, [TelemetryService]);
