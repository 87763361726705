import { ReactElement } from "react";
import { graphql } from "gatsby";
import { useDependency } from "../../contexts/DependencyContext";
import PostWidgetService, { WidgetProps } from "../../services/widgets/PostWidgetService";
import { DateService } from "../../services/DateService";
import Helmet from "../../components/Helmet";
import PostItem from "../../components/Shared/Post";
import Widget from "../../components/Shared/Widget";
import PostNavigation from "../../components/Blog/PostNavigation";
import * as styles from "./post.module.scss";

interface IPostProps {
    data: GatsbyTypes.PostQuery;
}

export default function Post({ data }: IPostProps): ReactElement {
    const widgetService = useDependency(PostWidgetService);
    const dateService = useDependency(DateService);
    const widgetsData = data?.strapi?.post?.content?.map(x => x as WidgetProps);
    const post = data.strapi.post;
    const previousPost = data.strapi.previousPosts && data.strapi.previousPosts.length > 0 ? (data.strapi.previousPosts[0] as GatsbyTypes.strapi_Posts) : undefined;
    const nextPost = data.strapi.nextPosts && data.strapi.nextPosts.length > 0 ? (data.strapi.nextPosts[0] as GatsbyTypes.strapi_Posts) : undefined;

    return (
        <>
            <Helmet title={`Post - ${data.strapi.post?.title}`} description="" />
            <div className={styles.singleBlog}>
                <div className="headerContainer">
                    <div className="mainBox">
                        <div className={styles.postCategory}>
                            {post?.post_categories?.map((x, i) => (
                                <span key={i}>{x?.name}</span>
                            ))}
                        </div>
                        <h1>{post?.title}</h1>
                        {post?.date && <span className={styles.postDate}>{dateService.formatDate(post.date)}</span>}
                    </div>
                </div>

                <div className={styles.postWrapper}>
                    <div className={styles.inner}>
                        <div>
                            {widgetsData
                                ?.filter(x => !!x)
                                .map(x => (
                                    <Widget key={widgetService.getKey(x)}>{widgetService.getWidget(x)}</Widget>
                                ))}
                        </div>

                        {data.strapi.posts && (
                            <>
                                <PostNavigation
                                    previousPost={previousPost}
                                    nextPost={nextPost}
                                    previousBtnText={data.strapi.postPage?.previousPostLinkText ?? ""}
                                    nextBtnText={data.strapi.postPage?.nextPostLinkText ?? ""}
                                />

                                <div className={styles.latestPosts}>
                                    <h2>{data.strapi.postPage?.latestPostsTitle ?? ""}</h2>
                                    <div>
                                        {data.strapi.posts.map(x => (
                                            <PostItem key={x?.id} post={x as GatsbyTypes.strapi_Posts} linkText={data.strapi.postPage?.postLinkText ?? ""} className={styles.postItem} />
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export const query = graphql`
    query Post($id: ID!) {
        strapi {
            postPage {
                latestPostsTitle
                postLinkText
                previousPostLinkText
                nextPostLinkText
            }
            post(id: $id) {
                id
                title
                date
                content {
                    ... on strapi_ComponentPostText {
                        __typename
                        id
                        body
                    }
                    ... on strapi_ComponentPostHighlightedText {
                        __typename
                        id
                        text
                    }
                    ... on strapi_ComponentPostMediaContent {
                        __typename
                        id
                        media {
                            id
                            url
                            url_sharp {
                                childImageSharp {
                                    gatsbyImageData(quality: 60)
                                }
                            }
                        }
                    }
                }
                post_categories {
                    name
                    color
                }
                products {
                    name
                    color
                }
            }
            previousPosts: posts(where: { id_lt: $id }, limit: 1, sort: "id:desc") {
                id
                date
                title
            }
            nextPosts: posts(where: { id_gt: $id }, limit: 1, sort: "id") {
                id
                date
                title
            }
            posts(where: { id_ne: $id }, limit: 3, sort: "date:desc,published_at:desc") {
                id
                date
                title
                summary
                post_categories {
                    name
                    color
                }
            }
        }
    }
`;
