import { ReactElement } from "react";
import { IGenericWidget } from "../../../services/widgets/IGenericWidget";

export default function NoWidget(props: IGenericWidget): ReactElement {
    return (
        <div>
            <p>Widget not found for type '{props.__typename}'</p>
        </div>
    );
}
