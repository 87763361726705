import { ReactElement } from "react";
import RichContent from "../../../Shared/RichContent";
import * as styles from "./Text.module.scss";

interface ITextProps {
    widgetProps: GatsbyTypes.strapi_ComponentPostText;
}

export default function Text({ widgetProps }: ITextProps): ReactElement {
    return (
        <div className={styles.blogText}>
            <RichContent content={widgetProps.body} />
        </div>
    );
}
