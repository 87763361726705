import { ContextCollection } from "../contexts/DependencyContext";

export class ContentService {
    async loadEmbeddedContentAsync(element: Element, url: string, styles: { [className: string]: string }): Promise<void> {
        try {
            if (!url || !element.parentNode) return;

            const containerElement = document.createElement("div");

            if (url.includes("youtube") || url.includes("youtu.be")) {
                this.configureYoutube(url, containerElement, styles);
            } else if (url.includes("vimeo")) {
                this.configureVimeo(url, containerElement, styles);
            } else if (url.includes("twitter")) {
                await this.configureTwitterAsync(url, containerElement, styles);
            } else if (url.includes("instagram")) {
                await this.configureInstagramAsync(url, containerElement, styles);
            } else {
                console.error(`Couldn't resolve content from '${url}'`);
            }

            element.parentNode?.replaceChild(containerElement, element);
        } catch (error) {
            console.error(error);
        }
    }

    executeScripts() {
        try {
            const windowObject = window as any;
            windowObject.instgrm?.Embeds?.process();
            windowObject.twttr?.widgets?.load();
        } catch (error) {
            console.error(error);
        }
    }

    private configureYoutube(url: string, containerElement: HTMLDivElement, styles: { [className: string]: string }) {
        const iframeElement = this.createIframeElement("YouTube video player", this.getYoutubeVideoUrl(url));
        iframeElement.allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
        containerElement.className = styles.videoContainer;
        containerElement.appendChild(iframeElement);
    }

    private configureVimeo(url: string, containerElement: HTMLDivElement, styles: { [className: string]: string }) {
        const iframeElement = this.createIframeElement("Vimeo video player", this.getVimeoVideoUrl(url));
        iframeElement.allow = "autoplay *; encrypted-media *";
        containerElement.className = styles.videoContainer;
        containerElement.appendChild(iframeElement);
    }

    private async configureTwitterAsync(url: string, containerElement: HTMLDivElement, styles: { [className: string]: string }): Promise<void> {
        const blockquoteElement = this.createBlockquoteElement(url, "twitter-tweet");
        containerElement.className = styles.postContainer;
        containerElement.appendChild(blockquoteElement);
        await this.loadTwitterObjectAsync();
    }

    private async configureInstagramAsync(url: string, containerElement: HTMLDivElement, styles: { [className: string]: string }): Promise<void> {
        const blockquoteElement = this.createBlockquoteElement(url, "instagram-media");
        blockquoteElement.dataset.instgrmCaptioned = "";
        containerElement.className = styles.postContainer;
        containerElement.appendChild(blockquoteElement);
        await this.addInstagramScriptAsync();
    }

    private createIframeElement(title: string, url: string): HTMLIFrameElement {
        const iframeElement = document.createElement("iframe");
        iframeElement.title = title;
        iframeElement.src = url;
        iframeElement.allowFullscreen = true;
        return iframeElement;
    }

    private createBlockquoteElement(url: string, className: string): HTMLQuoteElement {
        const aElement = document.createElement("a");
        aElement.href = url;
        const blockquoteElement = document.createElement("blockquote");
        blockquoteElement.className = className;
        blockquoteElement.appendChild(aElement);
        return blockquoteElement;
    }

    private async addInstagramScriptAsync(): Promise<void> {
        return new Promise((resolve, reject) => {
            const id = "instagram-ejs";

            if (document.getElementById(id)) {
                resolve();
                return;
            }

            const firstScript = document.getElementsByTagName("script")[0];
            const scriptElement = document.createElement("script");
            scriptElement.id = id;
            scriptElement.async = true;
            scriptElement.defer = true;
            scriptElement.onerror = e => reject(e);
            scriptElement.onload = () => resolve();
            scriptElement.src = "https://platform.instagram.com/en_US/embeds.js?omitscript=true";
            firstScript.parentNode?.insertBefore(scriptElement, firstScript);
        });
    }

    private async loadTwitterObjectAsync(): Promise<void> {
        return new Promise((resolve, reject) => {
            (window as any).twttr = (function () {
                const id = "twitter-wjs";
                const twitterObject = (window as any).twttr || {};

                if (document.getElementById(id)) {
                    resolve();
                    return twitterObject;
                }

                const firstScript = document.getElementsByTagName("script")[0];
                const scriptElement = document.createElement("script");

                scriptElement.id = id;
                scriptElement.onerror = e => reject(e);
                scriptElement.onload = () => resolve();
                scriptElement.src = "https://platform.twitter.com/widgets.js";
                firstScript.parentNode?.insertBefore(scriptElement, firstScript);

                twitterObject._e = [];
                twitterObject.ready = function (f: any) {
                    twitterObject._e.push(f);
                };

                return twitterObject;
            })();
        });
    }

    private getYoutubeVideoUrl(url: string): string {
        let videoId = url.includes("v=") ? url.split("v=")[1] : url.split("/").slice(-1)[0];
        const ampersandPosition = videoId.indexOf("&");
        if (ampersandPosition != -1) videoId = videoId.substring(0, ampersandPosition);
        return `https://www.youtube-nocookie.com/embed/${videoId}`;
    }

    private getVimeoVideoUrl(url: string): string {
        const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
        const match = url.match(regExp);
        const videoId = match && match[5];
        return `https://player.vimeo.com/video/${videoId}?dnt=true`;
    }
}

ContextCollection.registerSingleton(ContentService);
