import { CSSProperties, ReactElement, useRef } from "react";
import { useDependency } from "../../../contexts/DependencyContext";
import { DateService } from "../../../services/DateService";
import { arrows } from "../../Icons";
import { NavigationService } from "../../../services/NavigationService";
import { className } from "../../../shared/ClassName";
import * as styles from "./Post.module.scss";

interface IPostProps {
    post: GatsbyTypes.strapi_Posts;
    linkText: string;
    className?: string;
}

export default function Post({ post, linkText, className: cssName }: IPostProps): ReactElement {
    const dateService = useDependency(DateService);
    const navigationService = useDependency(NavigationService);
    const postCategory = post.post_categories && post.post_categories.length > 0 ? post.post_categories[0] : null;

    async function navigateToPostAsync(): Promise<void> {
        await navigationService.navigateAsync(`/blog/${post.id}`);
    }

    return (
        <div {...className(styles.post, cssName)} onClick={navigateToPostAsync} style={{ "--post-category-color": postCategory?.color } as CSSProperties}>
            <div className={styles.postWrapper}>
                {postCategory && (
                    <div className={styles.postCategory}>
                        <span>{postCategory.name}</span>
                    </div>
                )}
                <span className={styles.postDate}>{dateService.formatDate(post.date)}</span>
                <h3>{post.title}</h3>
                {post.summary && <div className={styles.postSummary}>{post.summary}</div>}
            </div>
            <div className={styles.postButton}>
                <div className={styles.innerButton}>
                    <p>{linkText}</p>
                    <div className={styles.iconContainer}>{arrows.right}</div>
                </div>
            </div>
        </div>
    );
}
