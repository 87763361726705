import { ReactElement } from "react";
import CtaLink from "../../Shared/CtaLink";
import { chevron } from "../../Icons";
import * as styles from "./PostNavigation.module.scss";

interface IPostNavigationProps {
    previousPost: GatsbyTypes.strapi_Posts | undefined;
    nextPost: GatsbyTypes.strapi_Posts | undefined;
    previousBtnText: string;
    nextBtnText: string;
}

const getPostLink = (id: string): string => `blog/${id}`;

export default function PostNavigation({ previousPost, nextPost, previousBtnText, nextBtnText }: IPostNavigationProps): ReactElement {
    return (
        <div className={styles.postNavigation}>
            {previousPost && (
                <div className={styles.prevBtn}>
                    <CtaLink cta={{ link: getPostLink(previousPost.id), external: false, id: "", text: "", group: "", iconSvg: undefined }}>
                        <div>
                            {chevron.left} <span>{previousBtnText}</span>
                        </div>
                        <span className={styles.btnTitle}>{previousPost.title}</span>
                    </CtaLink>
                </div>
            )}

            {nextPost && (
                <div className={styles.nextBtn}>
                    <CtaLink cta={{ link: getPostLink(nextPost.id), external: false, id: "", text: "", group: "", iconSvg: undefined }}>
                        <div>
                            <span>{nextBtnText}</span>
                            {chevron.right}
                        </div>
                        <span className={styles.btnTitle}>{nextPost.title}</span>
                    </CtaLink>
                </div>
            )}
        </div>
    );
}
