import { PropsWithChildren, ReactElement } from "react";
import { className } from "../../../shared/ClassName";
import * as styles from "./Widget.module.scss";

export default function Widget(props: PropsWithChildren<any>): ReactElement {
    return (
        <div className={styles.widget}>
            <div {...className(styles.content)}>{props.children}</div>
        </div>
    );
}
