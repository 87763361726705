import { GatsbyImage } from "gatsby-plugin-image";
import { ReactElement } from "react";
import { useModal } from "../../../../hooks/UseModal";
import { ImageModal } from "../../../Shared/Modals/ImageModal";

interface IGalleryImageProps {
    image?: GatsbyTypes.File;
}

export default function Image({ image }: IGalleryImageProps): ReactElement {
    const [showModal] = useModal(ImageModal, { image });

    return (
        <>
            {image?.childImageSharp?.gatsbyImageData && (
                <div onClick={showModal}>
                    <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="image" />
                </div>
            )}
        </>
    );
}
