import { ContextCollection } from "../contexts/DependencyContext";

export class DateService {
    formatDate(date: any): string {
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
        return new Date(date).toLocaleDateString(undefined, options);
    }
}

ContextCollection.registerSingleton(DateService);
