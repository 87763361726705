import { ReactElement } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { ModalFrame } from "../ModalFrame";
import { ModalContentProps } from "../../../../hooks/UseModal";
import { closeIcon } from "../../../Icons";
import * as styles from "./ImageModal.module.scss";

export interface ImageModalProps {
    image?: GatsbyTypes.File;
}

export function ImageModal({ image, onClose }: ModalContentProps<ImageModalProps, boolean>): ReactElement {
    function handleClose() {
        onClose(true);
    }

    return (
        <ModalFrame className={styles.imageFrame} useCard allowClose onClose={handleClose}>
            <div className={styles.modalContent}>
                {image?.childImageSharp?.gatsbyImageData && <GatsbyImage image={image.childImageSharp?.gatsbyImageData} alt="image" />}

                <button className={styles.close} onClick={handleClose}>
                    {closeIcon}
                </button>
            </div>
        </ModalFrame>
    );
}
