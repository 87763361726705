import { ReactElement, useEffect, useState, useRef } from "react";
import { useDependency } from "../../../contexts/DependencyContext";
import { ContentService } from "../../../services/ContentService";
import * as styles from "./RichContent.module.scss";

interface IRichContentProps {
    content: string | undefined;
}

export default function RichContent({ content }: IRichContentProps): ReactElement {
    const [loaded, setLoaded] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const contentService = useDependency(ContentService);

    useEffect(() => {
        if (loaded) return;
        async function loadEmbeddedResources(): Promise<void> {
            const embeddedResources = contentRef.current?.querySelectorAll("oembed[url]");

            if (embeddedResources) {
                for (let i = 0; i < embeddedResources?.length; i++) {
                    const element = embeddedResources[i];
                    const embeddedUrl = (element.attributes as any)?.url?.value as string;
                    await contentService.loadEmbeddedContentAsync(element, embeddedUrl, styles);
                }

                contentService.executeScripts();

                setLoaded(true);
            }
        }

        loadEmbeddedResources();
    }, [contentRef]);

    return <div dangerouslySetInnerHTML={{ __html: content ?? "" }} ref={contentRef}></div>;
}
