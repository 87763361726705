import { ReactElement } from "react";
import useSnapScroll from "../../../hooks/UseSnapScroll";
import Image from "./Image";
import { arrows } from "../../Icons";
import * as styles from "./ImageGallery.module.scss";

interface IImageGallery {
    allImages: GatsbyTypes.strapi_UploadFile[];
}

export default function ImageGallery({ allImages }: IImageGallery): ReactElement {
    const { galleryRef, progress, previousItem, nextItem } = useSnapScroll<HTMLDivElement>();
    const hideScrolling = progress < 0;
    const previousDisabled = !hideScrolling && progress === 0;
    const nextDisabled = !hideScrolling && progress === 1;

    return (
        <div className={styles.imageGallery}>
            <div className={`mainBox ${styles.inner}`}>
                {!hideScrolling && (
                    <div className={styles.arrowLeft}>
                        <button onClick={previousItem} disabled={previousDisabled}>
                            {arrows.left}
                        </button>
                    </div>
                )}

                <div className={hideScrolling ? styles.centeredGallery : styles.gallery} ref={galleryRef}>
                    {allImages
                        ?.filter(x => x.url_sharp?.childImageSharp?.gatsbyImageData)
                        ?.map(x => (
                            <Image key={x.url} image={x.url_sharp} />
                        ))}
                </div>

                {!hideScrolling && (
                    <div className={styles.arrowRight}>
                        <button onClick={nextItem} disabled={nextDisabled}>
                            {arrows.right}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
