import { ReactElement } from "react";
import * as styles from "./HighlightedText.module.scss";

interface ITextProps {
    widgetProps: GatsbyTypes.strapi_ComponentPostHighlightedText;
}

export default function HighlightedText({ widgetProps }: ITextProps): ReactElement {
    return (
        <div className={styles.blogHighlightedText}>
            <span>{widgetProps.text}</span>
        </div>
    );
}
